import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { AppBar, Container, Box, Grid } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Header title="Esters Liquor" />
      </AppBar>

      <Container
        component="main"
        sx={{ flexGrow: 1, padding: 2, display: "flex" }}
      >
        <Grid container>{children}</Grid>
      </Container>

      <Box component="footer" sx={{ color: "white", p: 2 }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
