import { HTTP_METHOD } from "../../constants";

 const fetchPhoto = async (name) => {    
    try {
        const url = `https://api.unsplash.com/search/photos?query=${name}`
        const response = await fetch(url, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`,
            },
            method: HTTP_METHOD.GET,
          });
          const photo =  await response.json()
          return photo.results[0].urls.thumb
    } catch (error) {
      console.error("Error fetching data from Unsplash API", error);
    }
  };

  export default fetchPhoto;