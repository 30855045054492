import { useState, useCallback, useEffect } from "react";
import defaultLogo from "../../images/default.jpg";
import fetchPhoto from "../../utils/component/fetchPhoto";
import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";

const ProductCard = (props) => {
  const { product, handleClick } = props;

  const [photo, setPhoto] = useState();
  const imageData = useCallback(async () => {
    const name = product?.name.split(":");
    const photo1 = await fetchPhoto(name[0]);
    setPhoto(photo1);
  }, [product]);

  useEffect(() => {
    imageData();
  }, [imageData]);

  return (
    <Card sx={{ width: 345, margin: 2 }} key={product?._id}>
      <CardMedia
        component="img"
        height="200"
        image={photo}
        alt={defaultLogo}
      ></CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h7" component="div">
          {product?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Size: {product?.unit}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Price: ${(product?.purchasingPrice / 100).toFixed(2)}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "50%" }}
          onClick={() => handleClick(product)}
        >
          Add
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
