import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

const initialState = {
  data: {},
  code: null,
  message: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, updateServiceState);
  },
});

