import React, { useEffect, useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { selectAllOrder, selectAccessToken } from "../redux/selectors";
import { getAllOrder } from "../redux/actions";
import OrderCart from "../components/Card/OrderCard";

const OrderDetailPage = () => {
  const token = useSelector(selectAccessToken);
  const allOrder = useSelector(selectAllOrder);
  const reduxStore = useStore();
  //const [ws, setWs] = useState(null);
  const ordersData = useCallback(() => {
    const serviceConfig = {
      token,
    };
    reduxStore.dispatch(getAllOrder(serviceConfig));
  }, [reduxStore, token]);

  useEffect(() => {
    // Create a WebSocket connection
    const websocket = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL);
    //setWs(websocket);

    // Handle incoming messages from the WebSocket server
    websocket.onmessage = () => {
      ordersData();
    };

    // Clean up on component unmount
    return () => {
      websocket.close();
    };
  }, [ordersData]);

  return (
    <>
      {allOrder.length > 0 ? <OrderCart orders={allOrder}></OrderCart> : null}
    </>
  );
};

export default OrderDetailPage;

