import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import InventoryPage from "./pages/InventoryPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ProductPage from "./pages/ProductPage";
import TABCPage from "./pages/TABCPage";
import CartPage from "./pages/CartPage";
import OrderDetailPage from "./pages/OrderDetailPage";
import StoreSetupPage from "./pages/StoreSetupPage";
import ClientSetupPage from "./pages/ClientSetupPage";
import LogoutPage from "./pages/LogoutPage";
import ConfimationPage from "./pages/ConfirmationPage";
import Layout from "./components/Layout/Layout";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/inventory" element={<InventoryPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/product/:productName" element={<ProductPage />} />
          <Route path="/tabc" element={<TABCPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/order" element={<OrderDetailPage />} />
          <Route path="/storeSetup" element={<StoreSetupPage />} />
          <Route path="/clientSetup" element={<ClientSetupPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/confirmation" element={<ConfimationPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
