import { useEffect, useCallback, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProductCard from "../components/Card/ProductCard";
import { useNavigate } from "react-router-dom";
import { useStore, useSelector } from "react-redux";
import { getAllProduct, getUser } from "../redux/actions";
import { selectAccessToken, selectAllProduct } from "../redux/selectors/index";
import { addItem } from "../redux/slice";
import SearchBar from "../components/Search/SearchBar";
import {
  CssBaseline,
  Container,
  Grid,
  Toolbar,
  Link,
  Pagination,
} from "@mui/material";

const sections = [
  { title: "Wine", url: "/product/wine" },
  { title: "Spirits", url: "/product/spirits" },
  { title: "Beer", url: "/product/beer" },
];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function HomePage() {
  const navigate = useNavigate();
  const reduxStore = useStore();
  const { dispatch } = reduxStore;
  const token = useSelector(selectAccessToken);
  const productList = useSelector(selectAllProduct);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // Pagination state
  const [page, setPage] = useState(1);
  const productsPerPage = 9; // Number of products per page

  const pageLoadData = useCallback(() => {
    if (token) {
      const serviceConfig = {
        token,
      };
      dispatch(getAllProduct(serviceConfig));
      dispatch(getUser(serviceConfig));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, token]);

  useEffect(() => {
    pageLoadData();
  }, [pageLoadData]);

  useEffect(() => {
    setFilteredProducts(productList);
  }, [productList]);

  const handleAddProduct = (product) => {
    const itemDetail = {
      id: product?._id,
      name: product?.name,
      sku: product?.sku,
      locationId: product?.location?.[0]._id,
      quantity: 1,
      subTotal: product?.purchasingPrice,
      unit: product?.unit,
      tax: product?.tax,
      discount: product?.discount,
      isPriceEdited: false,
    };
    dispatch(addItem(itemDetail));
  };

  const handleOnSearch = (searchProduct) => {
    if (searchProduct === "") {
      setFilteredProducts(productList);
    } else {
      const filtered = productList.filter((product) =>
        product.name.toLowerCase().includes(searchProduct.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  // Handle page change
  const handleChange = (event, value) => {
    setPage(value);
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  // Calculate the products to be displayed on the current page
  const displayedProducts = filteredProducts.slice(
    (page - 1) * productsPerPage,
    page * productsPerPage
  );
  console.log('displayedProducts', displayedProducts)
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Toolbar
          component="nav"
          variant="dense"
          sx={{ justifyContent: "space-between", overflowX: "auto" }}
          maxWidth="lg"
        >
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              sx={{ p: 1, flexShrink: 0 }}
            >
              {section.title}
            </Link>
          ))}
        </Toolbar>
        <SearchBar onSearch={(result) => handleOnSearch(result)} />
        <main>
          <Grid container spacing={6} sx={{ mt: 2, ml: -2 }}>
            {displayedProducts.map((productDetail) => (
              <ProductCard
                product={productDetail}
                handleClick={(product) => handleAddProduct(product)}
              />
            ))}
            
          </Grid>
          <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              color="primary"
              sx={{ marginTop: 4, display: "flex", justifyContent: "center" }}
            />
        </main>
      </Container>
    </ThemeProvider>
  );
}
