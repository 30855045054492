import { createSlice } from "@reduxjs/toolkit";
import {
  addCLient,
  updateClient,
  deleteClient,
  getClient,
} from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clientData: [],
    code: null,
    message: null,
  },
  reducers: {
    addClientDetail: (state, action) => {
      state.clientData.push(action.payload)
    },
    removeClient: (state) => {
      state.clientData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClient.fulfilled, updateServiceState);
    builder.addCase(addCLient.fulfilled, updateServiceState);
    builder.addCase(updateClient.fulfilled, updateServiceState);
    builder.addCase(deleteClient.fulfilled, updateServiceState);
  },
});

export const { addClientDetail, removeClient } = clientSlice.actions;
