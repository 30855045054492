import React from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Divider,
} from "@mui/material";

const OrderCart = (props) => {
  const { orders } = props;
  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      {orders.map((order, index) => (
        <Paper key={index} sx={{ padding: 3, marginBottom: 3 }} elevation={3}>
          <Typography variant="h5" gutterBottom>
            Order Details - {order._id}
          </Typography>
          <Divider />

          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1">
              Order ID: <strong>{order._id}</strong>
            </Typography>
            <Typography variant="subtitle1">
              Customer: <strong>{order.userName}</strong>
            </Typography>
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">TABC</TableCell>
                  <TableCell align="right">Size</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Discount</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.product.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{item.productName}</TableCell>
                    <TableCell>{item.tabc}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell align="center">{item.discount}</TableCell>
                    <TableCell align="right">
                      ${(item.subTotal/100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" sx={{ textAlign: "right", marginTop: 2 }}>
            Total: ${(order.totalPrice/100).toFixed(2)}
          </Typography>

          <Button variant="contained" color="primary" sx={{ marginTop: 3 }}>
            Confirm Order
          </Button>
        </Paper>
      ))}
    </Box>
  );
};

export default OrderCart;
