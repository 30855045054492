import { createSlice } from "@reduxjs/toolkit";
import { getAllOrder, addOrder, updateOrder, deleteOrder, checkOutOrder } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    data:[]
  },
  reducers: {
    addOrder: (state, action) => {
      state.items.push(action.payload)
    },
    removeItem: (state) => {
      state.value -= 1;
    },
    editItem: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOrder.fulfilled, updateServiceState);
    builder.addCase(addOrder.fulfilled, updateServiceState);
    builder.addCase(updateOrder.fulfilled, updateServiceState);
    builder.addCase(deleteOrder.fulfilled, updateServiceState);
    builder.addCase(checkOutOrder.fulfilled, updateServiceState);
  },
});

