import { createSlice } from "@reduxjs/toolkit";
import { getCart, addCart, updateCart, deleteCart } from "../../actions";
import { updateServiceState } from "../../../utils/redux/redux";
const groupItemsBySKU = (items) => {
  const groupedItems = {};

  items.forEach((item) => {
    if (groupedItems[item.sku]) {
      groupedItems[item.sku].quantity += item.quantity;
      groupedItems[item.sku].price += item.price;
    } else {
      groupedItems[item.sku] = { ...item };
    }
  });

  return Object.values(groupedItems);
};
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    data:{}
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload)
      state.items = groupItemsBySKU(state.items)
    },
    clearItems: (state) => {
      state.items = []
    },
    editItem: (state, action) => {
      state.value += action.payload;
    },
    deleteItems: (state, action) => {
      state.items  = state.items.filter((item) => item.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, updateServiceState);
    builder.addCase(addCart.fulfilled, updateServiceState);
    builder.addCase(updateCart.fulfilled, updateServiceState);
    builder.addCase(deleteCart.fulfilled, updateServiceState);
  },
});

export const { addItem, clearItems, deleteItems } = cartSlice.actions;
