import * as React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AccountMenu from "../AccountMenu/AccountMenu.js";
import { selectItems, selectAccessToken } from "../../redux/selectors/index.js";
import { AppBar } from "@mui/material";

const Header = (props) => {
  const { title } = props;
  const navigate = useNavigate();
  const token = useSelector(selectAccessToken);
  const items = useSelector(selectItems);
  const routeChange = (path) => {
    navigate(path);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
      <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
        {token && <AccountMenu />}
        <Typography
          component="h2"
          variant="h5"
          color="#1976d2"
          align="center"
          noWrap
          sx={{ flex: 1 }}
          onClick={() => routeChange("/")}
        >
          {title}
        </Typography>
        {token && (
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={() => routeChange("login")}
            >
              Login
            </Button>

            <Button
              variant="outlined"
              size="small"
              onClick={() => routeChange("signup")}
            >
              Sign up
            </Button>

            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => routeChange("cart")}
            >
              <AddShoppingCartIcon />
              <Typography
                component="h6"
                variant="h6"
                color="inherit"
                align="center"
                noWrap
                sx={{ mb: 2 }}
              >
                {items.length > 0 ? items.length : null}
              </Typography>
            </IconButton>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
