import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    ORDER_ADD_ENDPOINT_TYPE,
    ORDER_GET_ENDPOINT_TYPE,
    ORDER_UPDATE_ENDPOINT_TYPE,
    ORDER_DELETE_ENDPOINT_TYPE,
    ORDER_CHECKOUT_ENDPOINT_TYPE,
  HTTP_METHOD,
} from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getAllOrder = createAsyncThunk(
    ORDER_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { token } = serviceCongfig;
      let date = new Date().toLocaleDateString();
      const dataEndpoint = `order/list?startDate=${date}`
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrder = createAsyncThunk(
    ORDER_ADD_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.POST,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOrder = createAsyncThunk(
    ORDER_UPDATE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.PATCH,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOrder = createAsyncThunk(
    ORDER_DELETE_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { dataEndpoint, body, token } = serviceCongfig;
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.DELETE,
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkOutOrder = createAsyncThunk(
  ORDER_CHECKOUT_ENDPOINT_TYPE,
async (serviceCongfig, { rejectWithValue }) => {
  try {
    const { body, token } = serviceCongfig;
    const dataEndpoint = 'order/checkout'
    const url = `${baseUrl}/${dataEndpoint}`;
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: HTTP_METHOD.POST,
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    return rejectWithValue(error);
  }
}
);
