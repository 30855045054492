import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#fff', color: '#737373', py: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center">
          © 2024 Esters Liquor. All Rights Reserved.
        </Typography>
        <Typography variant="body2" align="center">
          Have questions? Contact us at{' '}
          <Link href="mailto:info@estersliquor.com" color="inherit">
            info@estersliquor.com
          </Link>{' '}
          or call{' '}
          <Link href="tel:+1234567890" color="inherit">
            +1 (234) 567-890
          </Link>.
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Link href="https://facebook.com/estersliquor" color="inherit">
            Facebook
          </Link>
          <Link href="https://twitter.com/estersliquor" color="inherit">
            Twitter
          </Link>
          <Link href="https://instagram.com/estersliquor" color="inherit">
            Instagram
          </Link>
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          <Link href="/privacy-policy" color="inherit">
            Privacy Policy
          </Link>{' '}
          |{' '}
          <Link href="/terms-of-service" color="inherit">
            Terms of Service
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
