import { useEffect, useCallback, useState } from "react";
import ProductCard from "../components/Card/ProductCard";
import { useNavigate } from "react-router-dom";
import { useStore, useSelector } from "react-redux";
import { getAllProduct, getUser } from "../redux/actions";
import { selectAccessToken, selectAllProduct } from "../redux/selectors/index";
import SearchBar from "../components/Search/SearchBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Container,
  Typography,
  Pagination,
} from "@mui/material";

export default function ProductPage() {
  const reduxStore = useStore();
  const { dispatch } = reduxStore;
  const token = useSelector(selectAccessToken);
  const products = useSelector(selectAllProduct);

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 10; // Number of products per page

  const pageLoadData = useCallback(() => {
    if (token) {
      const serviceConfig = {
        token,
      };
      dispatch(getAllProduct(serviceConfig));
    }
  }, [dispatch, token]);

  useEffect(() => {
    pageLoadData();
  }, [pageLoadData]);

  // Filter products based on the search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the total number of pages for filtered products
  const pageCount = Math.ceil(filteredProducts.length / rowsPerPage);

  // Calculate the products to display on the current page
  const displayedProducts = filteredProducts.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1); // Reset to the first page when search changes
  };

  return (
    <Container>
      <Typography variant="h6" component="h1" gutterBottom>
        Product Details
      </Typography>
      <TextField
        label="Search Products"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ marginBottom: 3 }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>UPC</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Size</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Discount</TableCell>
              <TableCell align="center">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedProducts.map((item) => (
              <TableRow key={item.id}>
                 <TableCell>{item.sku}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.mainCategory[0].code}</TableCell>
                <TableCell>{item.unit}</TableCell>
                <TableCell align="center">{item.location[0].quantity}</TableCell>
                <TableCell align="center">{item.discount}</TableCell>
                <TableCell align="right">
                  ${(item.purchasingPrice / 100).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {filteredProducts.length === 0 && (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          No products found.
        </Typography>
      )}
      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        color="primary"
        sx={{ marginTop: 4, display: "flex", justifyContent: "center" }}
      />
    </Container>
  );
}
