// ThankYouPage.js
import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const ConfimationPage = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '50px',
        }}
      >
        <Typography variant="h3" gutterBottom>
          Thank You!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your submission has been received.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We appreciate your feedback and will get back to you soon.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ marginTop: '20px' }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  );
};

export default ConfimationPage;
