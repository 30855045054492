import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button, Typography } from "@mui/material";
import { selectItems, selectAccessToken } from "../redux/selectors";
import { useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkOutOrder } from "../redux/actions";
import { clearItems, deleteItems } from "../redux/slice";

const columns = [
  { id: "sku", label: "SKU", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 50 },
  { id: "unit", label: "Size", minWidth: 50, align: "right" },
  { id: "quantity", label: "Quantity", minWidth: 50, align: "right" },
  { id: "discount", label: "Discount", minWidth: 50, align: "right" },
  { id: "subTotal", label: "Price", minWidth: 50, align: "right" },
];

const CartPage = () => {
  const token = useSelector(selectAccessToken);
  const reduxStore = useStore();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPrice, setTotalPrice] = useState(0);
  const rows = useSelector(selectItems);
  const [cartItems, setCartItems] = useState(rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
    reduxStore.dispatch(deleteItems(id));
  };

  const handleClick = (event, id) => {};
  useEffect(() => {
    if (cartItems.length > 0) {
      let price = 0;
      cartItems.map((items) => {
        price = price + items.subTotal;
      });
      setTotalPrice(price);
    }
  }, [cartItems]);

  const handlePlaceOrder = (event) => {
    event.preventDefault();
    const body = {
      product: rows,
      discount: 0,
      customerId: "",
      totalPrice,
      paymentMethod: "check",
      paymentStatus: "Completed",
    };
    const serviceConfig = {
      token,
      body,
    };
    reduxStore.dispatch(checkOutOrder(serviceConfig));
    reduxStore.dispatch(clearItems());
    navigate("/confirmation");
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <h1 style={{ textAlign: "center" }}>Cart Details</h1>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                        
                      );
                    })}
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => handleClick(event, row)}
                      >
                        Edit
                      </Button>
                      </TableCell>
                      <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDelete(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell colSpan={5} align="right">
                <Typography variant="h6">Total</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6">
                  ${(totalPrice / 100).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell colSpan={7} align="right">
                <Typography variant="h6"></Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cartItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        style={{ display: "block", margin: "auto" }}
        onClick={(event) => handlePlaceOrder(event)}
      >
        Place Order
      </Button>
    </Paper>
  );
};

export default CartPage;
