import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_GET_ENDPOINT_TYPE, HTTP_METHOD } from "../../../constants";

const baseUrl =  process.env.REACT_APP_BASE_URL

export const getUser = createAsyncThunk(
    USER_GET_ENDPOINT_TYPE,
  async (serviceCongfig, { rejectWithValue }) => {
    try {
      const { token } = serviceCongfig;
      const  dataEndpoint = "users/init"
      const url = `${baseUrl}/${dataEndpoint}`;
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: HTTP_METHOD.GET,
      });
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
