// components/SearchBar.js
import React, { useState } from 'react';
import { TextField } from '@mui/material';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (onSearch) {
      onSearch(event.target.value);  // Pass the search input to parent component
    }
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      label="Search Products"
      value={searchTerm}
      onChange={handleSearchChange}
      sx={{ mt: 2 }}
    />
  );
};

export default SearchBar;
